@charset "utf-8";

@import "src/scss/common";

.diagram-container {
    @include flex-grow(1);

    &.with-grid {
        //background-color: rgb(240, 240, 240);
        background-position: 6px 7px;
        background-size: 24px 24px;
        background-image: linear-gradient(0deg, transparent 24%, $grid-line-color 25%,
                $grid-line-color 26%, transparent 27%, transparent 74%,
                $grid-line-color 75%, $grid-line-color 76%, transparent 77%, transparent),
        linear-gradient(90deg, transparent 24%, $grid-line-color 25%, $grid-line-color 26%,
                        transparent 27%, transparent 74%, $grid-line-color 75%, $grid-line-color 76%, transparent 77%, transparent);
    }

    > * {
        height: 100%;
        min-height: 100%;
        width: 100%;
    }

    > div {
        cursor: default;
        overflow-x: auto;
        overflow-y: hidden;

        > div,
        > svg {
            overflow: visible;
        }
    }

    .node {
        cursor: initial;
    }
}
