@import "src/scss/common";

.numbers-section {
    .flag {
        @include shadow-card-1();
        width: 30px;
        border-radius: 5px
    }

    .number-tag {
        .tag-name {
            font-weight: bold;
            font-size: 0.8rem;
            margin-right: 1rem;
        }
    }
}
