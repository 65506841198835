@import "src/scss/common";


.section {
    padding: 20px !important;

    .container {
        @include flexbox();
        @include align-items(stretch);
        @include flex-direction(column);
        @include flex-grow(1);

        @media screen and (max-width: 1628px) {
            max-width: inherit;
            width: inherit;
        }
    }
}

.page-flex {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.page-body {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
}

.site {
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

html, head, body, .site {
    overflow: hidden !important;
}


.ui-window {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;

    $navbar-height: 48px;
    $menu-width: 200px;

    .upwire-navbar, .main-side-menu, .ui-content-area {
        position: absolute;
    }

    &.is-editor {
        .upwire-navbar {
            width: $menu-width;
            clip-path: inset(0 0px -25px 0);
        }

        .ui-content-area {
            top: 0;
            height: 100%;
        }
    }

    &.is-fullscreen {
        .ui-content-area {
            left: 0;
            width: 100%;
        }

        .main-side-menu {
            display: none;
        }
    }

    .upwire-navbar {
        top: 0;
        left: 0;
        width: 100%;
        height: $navbar-height;
        background-color: #fff;
        z-index: 150;
    }

    .main-side-menu {
        top: $navbar-height;
        left: 0;
        width: $menu-width;
        height: calc(100% - #{$navbar-height});
        z-index: 50;
        overflow-y: auto;
    }

    .menu-item-loading {
        margin-top: 2rem;
        display: block;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .ui-content-area {
        top: $navbar-height;
        left: $menu-width;
        width: calc(100% - #{$menu-width});
        height: calc(100% - #{$navbar-height});
        overflow-y: auto;
    }
}
