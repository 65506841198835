@charset "utf-8";

@import "src/scss/common";


.pg-inlet-bar {
    background-color: $tile-bg-alt3;
    border-bottom: solid 1px #EEE;
    color: $black;

    @include flexbox();
    @include flex-grow(0);
    @include align-items(center);
    @include justify-content(space-between);

    z-index: 2;

    height: 45px;

    .start,
    .end {
        padding: 0.15rem 0.55rem;
    }

    .start {
        @include flexbox();
        @include flex-grow(1);
        @include align-items(center);
        @include justify-content(flex-start);
        position: relative;
    }

    .end {

    }

    .pg-inlet-bar-icon {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(center);
        @include justify-content(center);
        position: relative;

        border-radius: 99999999999px;
        padding: 0.25rem;
        z-index: 2;
        text-align: center;
        color: #FFF;
    }

    .pg-inlet-bar-label {
        font-size: 1.1rem;
        padding-left: 0.5rem;
        font-weight: 500;
    }
}
