@charset "utf-8";

@import "src/scss/common";

.pg-canvas-actions {
    @include flexbox();
    @include flex-grow(1);
    @include align-items(center);
    @include justify-content(space-between);

    border: solid 1px #EEE;
    background-color: #D9D9D9;
    position: relative;
    bottom: -1px;

    color: $black;

    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .action {
        padding: 0.25rem;

        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);

        .icon {
            cursor: pointer;
            color: #FFF;

            &:hover,
            &:active {
                color: #777;
            }
        }
    }
}
