@charset "utf-8";

@import "src/scss/common";

.pg-canvas-bar {
    @include flexbox();
    @include flex-grow(1);
    @include align-items(flex-end);
    @include justify-content(space-between);

    z-index: 2;
    width: 100%;

    // DJR: If you change this, update .siphon-container padding-top (Filter.scss)
    height: 43px;

    .start,
    .end {
        @include align-items(flex-end);
    }

    .start {
        @include flexbox();
        @include flex-grow(1);
        @include align-items(center);
        @include justify-content(flex-start);
        position: relative;
    }

    .end {

    }
}
