@charset "utf-8";

@import "src/scss/common";

.pg-inlet-new-action {
    background-color: $tile-bg-alt1;
    color: $black;

    @include flexbox();
    @include flex-grow(0);
    @include align-items(center);
    @include justify-content(space-between);

    z-index: 2;

    height: 45px;

    &.have-inlets {
        border-top: dashed 1px #EEE;
    }

    .start,
    .end {
        padding: 0.15rem 0.55rem;
    }

    .start {
        @include flexbox();
        @include flex-grow(1);
        @include align-items(center);
        @include justify-content(flex-start);
        position: relative;
    }

    .end {

    }

    .pg-inlet-new-icon {
        @include flexbox();
        @include flex-grow(0);
        @include align-items(center);
        @include justify-content(center);
        position: relative;

        border-radius: 99999999999px;
        padding: 0.25rem;
        z-index: 2;
        text-align: center;
        color: #FFF;

        background-color: $blue;
    }

    .pg-inlet-new-label {
        font-size: 0.95rem;
        padding-left: 0.5rem;
        font-weight: 500;
        color: $blue;
    }

    .start {
        &.hover {
            cursor: pointer;

            .pg-inlet-new-icon {
                background-color: $dark-blue-lighter;
            }

            .pg-inlet-new-label {
                color: $dark-blue-lighter;
            }
        }
    }
}
