@charset "utf-8";

@import "../common";

form,
div {
    &.with-tabs {
        .modal-card-body {
            padding: 0;

            &.no-scroll {
                overflow: initial;
            }

            .tabs {
                margin: 0;

                a,
                div {
                    padding: 10px 15px;

                    &.with-icon {
                        padding: 10px 15px 10px 10px;
                    }
                }
            }

            .dynamic-form-errors,
            .tab-errors {
                .message {
                    margin: 20px 20px 0;
                }
            }

            .dynamic-form-sections,
            .tab-sections {
                .dynamic-form-section,
                .tab-section {
                    display: none;
                    padding: 20px;

                    &.is-nested {
                        padding: 0;

                        .tabs {
                            font-size: 0.8rem;
                        }
                    }

                    &.no-padding {
                        padding: 0;
                    }

                    &.active {
                        display: block;
                    }
                }
            }
        }
    }
}

.modal-card-foot {
    justify-content: flex-end !important;

    &.space-between {
        @include justify-content(space-between);
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;

    &.is-hidden {
        display: none;
    }
}

.dialog-container {
    .message {
        @include shadow-card-1();
    }
}

.MuiModal-root {
    z-index: 50000!important;
}

