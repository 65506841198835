@charset "utf-8";

@import "../../../scss/common";

.task-bar {
    @include flexbox();
    @include flex-grow(0);
    @include align-items(center);
    @include justify-content(space-between);

    background-color: $taskbar-background-color;
    color: $white;
    padding: 0.15rem 0.55rem;
    height: 45px;

    .start,
    .middle,
    .end {
        width: 33.33%;
    }

    .middle {
        text-align: center;
        font-size: 1.1rem;
        font-weight: 600;
    }
}

.task-actions {
    @include flexbox();
    @include flex-grow(0);
    @include align-items(center);
    @include justify-content(flex-end);

    font-size: 1.1rem;

    .action {
        padding: 0.25rem;

        .icon {
            cursor: pointer;

            &:hover,
            &:active {
                color: #EEE;
            }
        }
    }
}
