@charset "utf-8";

.modal {
    opacity: 1;
    transition: opacity 300ms ease-in-out;

    .modal-card-title {
        &.with-icon {
            .icon {
                margin: 0;
                margin-right: 8px;
                top: 3px;
                position: relative;
                max-width: 1.5rem;

                svg {
                    max-width: 1.5rem;
                }
            }
        }
    }

    .modal-card-body {
        &.scroll-overflow {
            max-height: calc(100vh - 190px);
            overflow-y: auto;
        }
    }
}

