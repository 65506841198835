@import "../common";

.big-selector {
    @include shadow-card-3();

    max-width: 1024px;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    background-color: $white;
    border-radius: 5px;
    overflow: hidden;

    .spacer {
        flex: 1;
    }

    .big-selector-header {
        background-color: $upwire-blue-1;
        display: flex;
        color: $white;

        h1, .actions {
            margin-right: 1rem;
            margin-left: 1rem;
            line-height: 3rem;
        }

        h1 {
            font-weight: bold;
            font-size: 1.1em;
            user-select: none;
            display: block;
            flex: 1 0 0;
            text-align: center;
        }

        .actions {
            justify-content: flex-end;
            display: flex;

            svg {
                font-size: 0.75em;
                color: $white;
            }
        }
    }

    .big-selector-content {
        max-height: 80vh;
        overflow-y: auto;

        .big-item-list {

            li.big-list-item {

                i, svg {
                    font-size: 1.5em;
                    color: $upwire-blue-2;

                    &.warning {
                        color: $upwire-orange-1;
                    }
                }

                .big-item-info {

                    .big-item-name {
                        font-weight: bold;
                    }

                    .big-item-subtitle {
                        font-size: 0.8em;
                        font-style: italic;
                    }
                }

                &:not(:hover) {
                    .big-item-actions {
                        opacity: 0;
                        pointer-events: none;
                    }
                }

                &:hover {
                    .big-item-actions {
                        animation: show-list-item-action 0.15s ease-in-out;
                        pointer-events: auto;
                    }
                }

                .big-item-actions-container {
                    flex: 0 0 50px;
                    display: flex;
                    width: 100%;
                }

                .big-item-actions {
                    display: flex;
                    justify-content: center;
                    align-items: center;


                    button {
                        svg {
                            font-size: 1em;
                            color: $upwire-blue-1;
                        }

                        &.delete {
                            svg {
                                color: $upwire-red-1;
                            }
                        }

                        margin-right: 1em;
                    }
                }
            }

            .listing {
                display: flex;
                padding: 0.5rem;
                align-items: center;
                width: 100%;

                .inline-component {
                    margin-right: 1rem;
                }

                .MuiListItemIcon-root {
                    display: block;
                }
            }
        }

        .big-selector-empty {
            &.default {
                text-align: center;
                padding: 1rem;
            }
        }

        .big-selector-loading{
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100px;
        }
    }
}
