@charset "utf-8";

@import "src/scss/common";

.pg-debugger-content {
    .pg-debugger-tab-content {
        // hacky, but too many other places to set min-height: 100%
        // (goal is to push the inline form controls to the bottom)
        min-height: 180px;

        .tab-content {
            max-height: calc(100vh - 300px);
            overflow-y: auto;
        }
    }
}

.query-results {
    &.no-results {
        padding: 0.75rem;
        font-weight: 600;
        font-size: 0.85rem;
    }

    &.with-results {
        padding: 0;
        overflow: hidden;

        .query-result {
            @include flexbox();
            @include flex-grow(1);
            @include align-items(center);
            @include justify-content(space-between);
            @include flex-direction(row);

            padding: 0.25rem 0.5rem;
            font-weight: 600;
            border-bottom: solid 0.5px $tile-bg-alt3;

            .result-field {
                width: 27%;
                font-size: 0.8rem;

                .tag {
                    font-size: 0.65rem;
                    line-height: 1.3;
                    height: 1.2rem;
                }

                .button {
                    &.disabled {
                        cursor: not-allowed;
                    }
                }

                &.actions {
                    @include flexbox();
                    @include justify-content(flex-end);

                    width: 20%;
                }
            }
        }
    }
}
